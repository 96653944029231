.overlay {
}
.content {
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .overlay {
    animation: fade 150ms ease forwards;
  }

  .content {
    animation: fade 150ms ease forwards;
  }
}
