@keyframes copy1 {
  0% {
    opacity: 1;
  }
  28% {
    opacity: 1;
  }
  33% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  95% {
    opacity: 0;
  }
}

.copyOne {
  @apply absolute top-20 left-0 w-full h-full;
  opacity: 1;
  animation: copy1 20s infinite;
}

@keyframes copy2 {
  0% {
    opacity: 0;
  }
  28% {
    opacity: 0;
  }
  33% {
    opacity: 1;
  }
  61% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
}

.copyTwo {
  @apply absolute top-20 left-0 w-full h-full;
  opacity: 0;
  animation: copy2 20s infinite;
}

@keyframes copy3 {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 0;
  }
  61% {
    opacity: 0;
  }
  66% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
}

.copyThree {
  @apply absolute top-20 left-0 w-full h-full;
  opacity: 0;
  animation: copy3 20s infinite;
}

.loginContainer {
  @apply hidden desktop:block relative desktop:col-span-5 bg-[#A3A5F9];
  background-image: url("/images/authentication-screen/background.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.deviceAnimation {
  @apply w-[600px] relative mx-auto top-50;
}

@media screen and (min-height: 800px) {
  .deviceAnimation {
    @apply !top-60;
  }
}

@media screen and (min-height: 1000px) {
  .deviceAnimation {
    @apply !top-80;
  }
}

@media screen and (min-height: 1200px) {
  .deviceAnimation {
    @apply !top-[650px];
  }
}

@media screen and (min-height: 1500px) {
  .deviceAnimation {
    @apply !top-[850px];
  }
}
